.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #f99123;
  /* stroke-linecap: round; */
  /* stroke-linejoin: round; */
}

.circle-dashes {
  stroke: #f4f4f4;
  fill: none;
}

.progress {
  height: 25px !important;
  width: 250px;
  overflow: hidden;
  background-color: grey;
  border-radius: 20px !important;
}

.progress-bar {
  display: inline-block;
}

.bg-color1 {
  background-color: #32b24a !important;
}

.bg-color2 {
  background-color: rgb(255, 255, 0) !important;
}

.bg-color3 {
  background-color: rgb(255, 165, 0) !important;
}

.bg-color4 {
  background-color: #ff0000 !important;
}

.slider {
  position: relative;
}

.slider .rangeslider.rangeslider-horizontal .rangeslider__labels {
  width: 250px;
}

.slider .progress {
  z-index: 99;
  position: absolute;
  bottom: 15px;
  left: 0px;
}

.slider .rangeslider.rangeslider-horizontal .rangeslider__handle {
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  z-index: 999;
  border: none;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  background: transparent;
  box-shadow: none;
  border-radius: 0px;
  border-top: 15px solid #000;
  pointer-events: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.select-icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDE1LjRMNiA5LjRMNy40IDhMMTIgMTIuNkwxNi42IDhMMTggOS40TDEyIDE1LjRaIiBmaWxsPSIjNDE0MDQyIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  border: none;
  color: "#414042";
}

.select-icon .dropdown-toggle {
  color: #414042;
  background-color: transparent;
  border: none;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #414042 !important;
  background-color: transparent !important;
  border: none !important;
}

.select-icon .dropdown-toggle:hover,
.select-icon .dropdown-toggle:active {
  color: #414042;
  background-color: transparent;
  border: none;
}

.select-icon .dropdown-toggle span {
  padding-right: 15px;
}

.dropdown-toggle::after {
  display: none !important;
}

a {
  text-decoration: none !important;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.btn-primary-new {
  background: #0bbbea !important;
  border-color: #0bbbea !important;
  color: white !important;
  height: 50px !important;
  font-family: "Barlow" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.btn-cancel {
  position: absolute;
  right: 11%;
  bottom: 10px;
  font-size: 13px;
  padding: 5px 10px;
  line-height: 150%;
  z-index: 99;
  border-color: #025173;
  background: white;
  color: black;
}

.btn-cancel:hover {
  background: #025173;
  border-color: #025173;
  color: white;
}

.btn-apply {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 13px;
  padding: 5px 10px;
  line-height: 150%;
  z-index: 99;
  background: #025173;
  border-color: #025173;
  color: white;
}

.btn-apply:hover {
  background: white;
  border-color: #025173;
  color: #025173;
}

.Day-filled-1322 {
  background-color: #025173 !important;
}

.Day-outlined-1321 {
  border: 1px solid #025173 !important;
}

.login-text {
  color: #0bbbea;
  font-family: "Barlow";
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 26.5px;
}

.form-control {
  height: 49px;
  padding-left: 42px !important;
  color: #000;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-control::placeholder {
  color: #bcbcbc;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-icon {
  position: absolute;
  left: 3%;
  top: 23%;
  text-align: center;
}
span.toggle-btn.input-icon {
  position: absolute;
  left: calc(100% - 30px) !important;
  top: 23%;
  text-align: center;
}
.gray-text {
  color: #555;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6.19px;
}

.gray-text-bold {
  color: #555;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.link-text {
  color: #0bbbea;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-text {
  color: #377abf;
  font-family: "Inter";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.active-text {
  color: #0bbbea;
  text-align: center;
  font-family: "Barlow";
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active-desc {
  color: #555;
  text-align: center;
  font-family: "Barlow";
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-title {
  color: #025173;
  font-family: "Barlow";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-tabs {
  margin-right: 64px;
  width: auto;
  color: #39393b;
  font-family: "Barlow";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.admin-tabs.active {
  color: #377abf;
}

.divide {
  min-height: 1px;
  height: 1px;
  max-height: 1px;
  background-color: #bcbcbc;
}

.admin-subtitle {
  margin-top: 18px;
  /* margin-bottom: 30px; */
  color: #025173;
  font-family: "Barlow";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-subtitle-text {
  color: #000;
  font-family: "Barlow";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-control-normal {
  padding: 0px 10px;
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 39.815px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
}

.form-control-normal::placeholder {
  color: #777;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.left-margin {
  margin-left: 147px !important;
}
.left-margin-n {
  margin-left: -147px !important;
}

.profile-view {
  margin-bottom: 16px;
}

.basis-title {
  color: #000;
  font-family: "Barlow";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.basis-icon {
  margin-bottom: 0.5rem;
  margin-left: 23px;
}

.basis-desc {
  color: #555;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-update {
  box-shadow: none;
  background-color: #000;
  border: none;
  color: #fff;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 20px;
  border-radius: 10px;
}

.password-type {
  color: #777;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px !important;
  margin-bottom: 18px !important;
}

.password-type ul {
  padding-left: 1rem;
  margin-bottom: 0px !important;
}

.change-password-view {
  padding-right: 40px !important;
}

.fouling-metrics__content > *:not(:last-child) {
  margin-bottom: 30px;
}

@media (width <=960px) {
  .user {
    margin-left: 10px;
  }

  .layout-topbar {
    padding-left: 10px;
  }

  .layout-topbar .layout-topbar-logo img {
    height: 33px;
  }

  .fancy-boxes {
    display: flex;
    flex-wrap: wrap;
  }

  .fancy-boxes > div {
    width: 100%;
  }

  .grid-base {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .performance-metrics {
    width: 100%;
  }

  .grid-base .right-border {
    border: none;
  }

  .fouling-metrics__content {
    padding: 0px;
    /* padding-right: 30px; */
  }

  .fouling-metrics.grid3 > div {
    padding: 10px 10px 20px 40px;
  }

  .left-margin {
    margin-left: 10px !important;
  }

  .box-shadow-login {
    padding: 20px !important;
  }
}

.grid-green {
  margin-top: 21px;
  background-color: #f4f4f4;
  padding: 15px 25px;
}

.bg-gray-color {
  background-color: #f4f4f4;
}

.new-user-btn {
  color: black !important;
  border: 1px solid black !important;
  font-family: "Barlow" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 7px 50px !important;
}
.user-title-btn-wrapper .new-user-btn-wrapper .new-user-btn:active {
  color: black !important;
  background-color: transparent !important;
  border: 1px solid black !important;
}

.new-user-btn:hover {
  color: black !important;
  background-color: transparent !important;
}

.select-wrapper .form-select {
  margin-right: 10px;
  width: auto;
  background-color: rgba(11, 187, 234, 0.2);
  border: none;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.select-wrapper button {
  margin-right: 18px;
  width: auto;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.user-content-wrapper {
  margin-top: 20px;
}

.user-content-wrapper p {
  color: #025173;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  margin: 8px 0px;
}

.user-content-wrapper p span {
  margin-right: 6px;
  margin-left: 6px;
}

.table-head {
  border-top: 0.4px solid rgba(188, 188, 188, 0.5);
}

.table-head th {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(119, 119, 119, 1);
}

.heading-col div {
  color: rgba(119, 119, 119, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.user-content-wrapper .td-email {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.user-content-wrapper .td-email a {
  color: rgba(2, 81, 115, 1) !important;
}

.user-content-wrapper td,
.user-content-wrapper th {
  vertical-align: middle;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: rgba(57, 57, 59, 1);
}

@media only screen and (min-width: 576px) {
  .new-user-btn-wrapper {
    float: right;
  }
}
@media only screen and (max-width: 767px) {
  .new-user-btn {
    padding: 3px 25px !important;
  }
}
.rdt_TableRow:hover {
  background-color: rgba(244, 244, 244, 1);
}

.rdt_TableCell {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.rdt_TableHeadRow {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 30px !important;
}

.dropdown-menu.show {
  background: #fff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
  padding-left: 14px;
  padding-right: 18px;
  padding-top: 16px;
  padding-bottom: 23px;
}

.dropdown-menu-text {
  color: #377abf;
  font-family: "Barlow";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dropdown-item {
  color: #707c82;
  font-family: "Barlow";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #377abf !important;
}
.dropdown-item:active span {
  color: #fff !important;
}
.dropdown-item:active svg,
.dropdown-item:active svg path {
  fill: #fff !important;
}
.customer-name-link {
  cursor: pointer;
}

.test-animation {
  opacity: 0;
  transition: all 1.3s ease-out;
}

.select-icon .dropdown-item svg {
  width: 20px;
}
.custom-react-chart {
  margin-top: 0px;
  width: 98%;
}
@media screen and (min-width: 767px) {
  .layout-main {
    max-width: calc(100% - 50px);
  }
}
@media screen and (max-width: 767px) {
  .admin-tabs {
    margin-right: 30px;
  }

  .layout-main-container {
    padding: 9rem 2rem 2rem 2rem;
    margin-left: 0px;
  }
  .custom-react-chart {
    margin-top: 30px;
    /* width: 480px; */
  }
}
.list-view .home {
  display: flex;
}
.list-view .home-heading-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.home-img-wrapper {
  width: 100%;
  max-width: 220px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* justify-content: center;
  margin: auto; */
}
.home-img-building-wrapper {
  background-color: #e9e9e9;
}
.home-img-coil-wrapper {
  background-color: #025173;
}
.home-img-coil-wrapper .home-coil-image {
  width: 133px;
}
.home-hero-img-building {
  width: 111px;
  height: 96px;
  margin: auto;
}
.home-hero-img {
  margin: auto;
}
.grid-title {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 13px !important;
  color: #000000;
  margin-bottom: 0px;
}
.grid-sub-title {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.slick-disabled path {
  fill: #7f7d7d;
}
.slick-next:before,
.slick-prev:before {
  color: #000;
}
.slick-prev:before {
  content: "";
}
.slick-next:before {
  content: "";
}
.slick-prev,
.slick-next {
  left: auto;
  right: 0px;
  top: -22px;
}
.slick-prev {
  right: 30px;
}
.home-slick-wrapper {
  margin-bottom: 30px;
}
.home-title {
  font-family: Barlow;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.home-view-all {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #0bbbea;
  cursor: pointer;
}
.custom-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -7px;
}
.custom-row .custom-col {
  max-width: 20%;
  width: 100%;
}
.empty-search-string {
  display: flex;
  font-size: 16px;
  color: #025173;
  font-family: "Barlow Condensed";
  font-weight: 500;
  justify-content: space-around;
}
.admin-tabs[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.chart-dropdown select {
  max-width: 172px;
  border: none;
  background-position: right center;
  padding-right: 1.5rem;
  /* margin-right: 30px; */
}
.chart-dropdown select:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem transparent;
}

.user-title-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (max-width: 400px) {
  .user-title-btn-wrapper {
    display: block;
  }
}

.form-control:focus {
  color: #000000 !important;
  border-color: #000000 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.input-wrapper.focused .input-icon svg path,
.input-wrapper:target .input-icon svg path {
  fill: #0bbbea !important;
}
.input-wrapper.focused .input-icon.toggle-btn svg path,
.input-wrapper:target .input-icon.toggle-btn svg path {
  fill: #777777 !important;
}
.update-password-title {
  font-family: "Barlow";
  font-size: 38px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  color: #0bbbea;
}

.focused .form-control::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000 !important;
}
.focused .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000 !important;
  opacity: 1;
}
.focused .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000 !important;
  opacity: 1;
}
.focused .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
}
.focused .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000 !important;
}

.focused .form-control::placeholder {
  /* Most modern browsers support this now. */
  color: #000 !important;
}
.header_account {
  max-width: 20%;
  margin-right: 5rem;
}
.header_content {
  width: 90%;
}
.header_account .select-icon .dropdown-toggle {
  white-space: break-spaces !important;
  display: flex;
  align-items: center;
}
.header_account .user {
  width: 0%;
}
.custom-header-wrapper {
  position: fixed;
  background-color: white;
  width: -webkit-fill-available;
  z-index: 1001;
}
.customerheader-layout-topbar.layout-topbar {
  background: linear-gradient(
    -45deg,
    transparent 0,
    transparent 50px,
    #f4f4f4 50px,
    #f4f4f4 0vw
  );
  display: flex;
  padding: 0 5px 0 7rem;
  height: 4.43rem;
  z-index: 997;
}
.authheader-layout-topbar .header-title {
  padding-left: 46px;
}
.input-icon {
  left: 14px !important;
}
.layout-topbar.authheader-layout-topbar {
  padding-left: 100px;
}
.login-logo {
  margin-left: -130px;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .layout-topbar.authheader-layout-topbar {
    padding-left: 10px;
  }
  .customerheader-layout-topbar.layout-topbar {
    padding: 0 2px 0 10px;
    width: 258px;
  }
  .header_content {
    width: 60%;
  }
  .header_account {
    max-width: 40%;
    margin-right: 3rem;
  }
  .home-img-wrapper {
    max-width: 180px;
  }
}

@media only screen and (max-width: 1441px) {
  .login-box {
    max-width: 458px !important;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1543px) {
  .login-box {
    max-width: 458px !important;
  }
  .login-box-image {
    min-width: 790px;
  }
}
.layout-main-container-authheader {
  margin-left: 0px !important;
  padding-left: 174px;
  padding-right: 0;
}
.layout-main-container-authheader .layout-main {
  max-width: unset !important;
}
.layout-main-container-authheader .box-shadow-login {
  padding-top: 25px !important;
}
@media only screen and (max-width: 991px) {
  .login-logo {
    min-width: 840px;
  }
  .header_content {
    width: 70%;
  }
  .header_account {
    max-width: 30%;
    margin-right: 4rem;
  }
  .layout-topbar .layout-topbar-logo {
    margin-left: 10px;
  }
  .layout-topbar .layout-topbar-logo img {
    height: 20px;
  }
}
/* @media only screen and (max-width: 1439px){
  .login-box-image{
    min-width: 790px;
  }
} */
@media only screen and (max-width: 467px) {
  .header_content {
    width: 60%;
  }
  .header_account {
    max-width: 40%;
    margin-right: 1rem;
  }
  .layout-topbar .layout-topbar-logo {
    margin-left: 10px;
  }
  .layout-topbar .layout-topbar-logo img {
    height: 22px;
  }
}
@media only screen and (min-width: 1441px) {
  .layout-main-container-authheader .layout-main {
    max-width: 1499px !important;
  }
}
.login-box-image {
  max-width: calc(100% - 458px) !important;
}
@media only screen and (min-width: 991px) and (max-width: 1543px) {
  .login-box-image {
    width: 100% !important;
  }
}
.layout-main-container.layout-main-container-pages {
  /* padding: 9rem 123px 2rem 123px !important; */
}
.admin-subtitle {
  margin-bottom: 0px !important;
}
/* Admin Buildings Bug Fixes  */
.rdt_TableHeadRow,
.rdt_TableCol {
  min-height: 30px !important;
}
.header-line {
  height: 17px;
  background-color: #000000;
  width: 1px;
}
.breadcrumbs-title {
  font-size: 16px;
  font-family: Barlow;
  font-weight: 500;
  color: #000;
}

.breadcrumbs-subtitle {
  font-size: 16px;
  font-family: Barlow;
  font-weight: normal;
  color: #000;
}
.doughnutText {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 72px;
  font-weight: 400;
  line-height: 86px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Barlow Condensed";
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

/* div box */
body {
  background-color: #babac0;
}
.overflow {
  min-height: 100vh;
}
.dropdown-item {
  cursor: pointer !important;
}
