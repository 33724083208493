/* @import url("./assets/fonts/BarlowCondensed-SemiBold.woff2");
@import url("./assets/fonts/BarlowCondensed-Regular.woff2");
@import url("./assets/fonts/BarlowCondensed-Medium.woff2");
@import url("./assets/fonts/BarlowCondensed-Light.woff2");
@import url("./assets/fonts/BarlowCondensed-Bold.woff2");
@import url("./assets/fonts/Barlow-SemiBold.woff2");
@import url("./assets/fonts/Barlow-Regular.woff2");
@import url("./assets/fonts/Barlow-Medium.woff2");
@import url("./assets/fonts/Barlow-Light.woff2");
@import url("./assets/fonts/Barlow-Bold.woff2"); */

.light-gauge {
  position: relative;
  min-width: 180px;
  min-height: 180px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
}

.light {
  position: absolute;
  left: 50%;
  right: 50%;
  margin: 1px;
  margin-left: calc(var(--width) / -2);
  margin-top: 2px;
  width: calc(var(--width) - 1px);
  height: var(--size);
  clip-path: polygon(
    calc(50% - var(--innerWidth) / 2 + 0.5px) 0,
    calc(50% - var(--innerWidth) / -2 - 0.5px) 0,
    100% 100%,
    0% 100%
  );
  z-index: calc(var(--lights) - var(--index) + 1);
  transform: rotate(calc(0deg + 360deg * var(--percent)))
    translateY(calc(var(--radius) - 12px));
  transition-delay: calc(var(--percent) * 0.35s);
  color: transparent;
  background: var(--color);
  padding: 0;
}

.color-progress {
  background: linear-gradient(
    90deg,
    #34b04a,
    #34b04a 25%,
    #f9b418 25%,
    #f9b418 50%,
    #f06a25 50%,
    #f06a25 75%,
    rgb(218, 48, 48) 75%
  );
  height: var(--size);
  border-radius: var(--size);
  box-shadow: inset 0 calc(var(--size) / 4) #00000040;
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
}

.cursor {
  transition: transform 0.2s;
  background: black;
  width: calc(var(--size) * 0.8);
  height: calc(var(--size) * 0.6);
  transform: translate(
    calc(var(--width) * var(--percent)),
    calc(var(--size) * -0.2)
  );
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.empty-text {
  color: #025173;
  text-align: center;
  font-size: 32px;
  max-width: 500px;
}

.card {
  height: 180px !important;
}

.to-next-cleaning .col-3:first-of-type .p-progressbar {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.to-next-cleaning .col-3:last-of-type .p-progressbar {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.to-next-cleaning .p-progressbar {
  border-radius: 0 !important;
}

.ball {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #bec5cc;
  background-color: var(--surface-border);
}

.ball.one {
  top: 10px;
  left: 10px;
}

.ball.two {
  top: 10px;
  right: 10px;
}

.ball.three {
  bottom: 10px;
  right: 10px;
}

.ball.four {
  bottom: 10px;
  left: 10px;
}

#app .performance-metrics h3 {
  margin-bottom: 25px;
}

.big-number {
  text-align: center;
  padding: 0;
  color: #000;
  font-size: 48px;
  margin-bottom: 8px;
  font-family: "Barlow Condensed";
  font-weight: 400;
}

.fancy-number {
  background: linear-gradient(125deg, #025173, #025173 65%, white 65%);
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin-bottom: 17px;
  height: 73px;
  position: relative;
}

.fancy-number > :first-child {
  font-size: 16px;
  margin-right: 7%;
  color: #fff;
  flex: 0 0 37%;
  font-family: "Barlow Condensed";
  font-weight: 500;
}

.fancy-number :last-child {
  text-align: right;
  flex: 1 1 auto;
  color: #000;
  font-family: "Barlow Condensed";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fancy-number .info-icon {
  color: gray;
  position: absolute;
  bottom: -9px;
  left: 61%;
}

@media screen and (max-width: 1440px) {
  .fancy-number .info-icon {
    left: 58%;
  }
}

.fancy-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 5px 10px;
}

.fancy-boxes > div {
  position: relative;
  background: white;
  border: 1px solid;
  padding: 8px 25px 15px 19px;
  border: 1px solid #6bb144;
}

.fancy-boxes .colored {
  color: #6bb144;
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.fancy-boxes .colored.pi {
  display: inline-block;
  margin-right: 5px;
}

.fancy-boxes .info-icon {
  position: absolute;
  right: 10px;
  bottom: 0px;
}

.grid-base {
  width: 100%;
  display: grid;
  gap: 0px;
  --base-color: #025173;
}

.grid-base h2 {
  color: var(--base-color);
  margin: 0 20px;
  font-family: "Barlow Condensed";
  font-weight: 400;
}

.grid-base h3 {
  color: var(--base-color);
  font-size: 22px;
  margin: 0 20px;
  font-family: "Barlow Condensed";
  font-weight: 400;
}

.grid-base h4 {
  text-align: center;
  color: var(--base-color);
  font-size: 16px;
  margin: 18px 5px 18px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}

.grid-base hr {
  border-bottom: 3px solid #e9e9e9;
  width: 100%;
  margin: 0px !important;
}
@media only screen and (min-width: 1441px) {
  .grid-base hr {
    width: 80%;
  }
}
.grid-base .right-border {
  border-right: 3px solid lightgrey;
}

.grid-green {
  --base-color: #6bb144;
}

.grid2 {
  grid-template-columns: 1fr 1fr;
}

.grid3 {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.chart-layout .grid3 {
  gap: 0;
}

.grid-base > div {
  padding: 10px 30px;
}

.grid3 > div {
  background: #f4f4f4;
  min-height: 100px;
  position: relative;
  padding: 10px 20px;
}

.grid3 > div:after {
  content: ".";
  color: transparent;
  position: absolute;
  background: var(--base-color);
  top: 0;
  left: 0;
  width: 35px;
  height: 22px;
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
}

.chart-layout .grid3 > div:not(:first-child)::after {
  display: none;
}

.grid3 .c2 {
  grid-column: span 2;
}

.grid3 .c3 {
  grid-column: span 3;
}
.gateway {
  width: 200px;
  height: auto;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 32px;
}
.gateway img {
  max-width: 200px;
  max-height: 200px;
  padding-bottom: 15px;
  border-bottom: 3px solid #e9e9e9;
}

.my-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.my-grid img {
  height: 180px;
  width: auto;
}

.card {
  position: relative;
}

.card .info-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 10px;
}

.performance-metrics {
  padding: 10px 20px !important;
}

.performance-metrics h3 {
  margin: 0px 20px 36px !important;
}

body {
  font-family: "Barlow";
}

.pi-calendar-plus:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'%3E%3Cpath d='M7.77407 12.5156L5.31975 9.99111L6.32222 8.96L7.77407 10.4533L10.6778 7.46666L11.6802 8.49777L7.77407 12.5156ZM3.66049 15.1111C3.28024 15.1111 2.95473 14.9718 2.68394 14.6933C2.41316 14.4148 2.27777 14.08 2.27777 13.6889V3.73333C2.27777 3.34222 2.41316 3.0074 2.68394 2.72889C2.95473 2.45037 3.28024 2.31111 3.66049 2.31111H4.35185V0.888885H5.73456V2.31111H11.2654V0.888885H12.6481V2.31111H13.3395C13.7197 2.31111 14.0453 2.45037 14.316 2.72889C14.5868 3.0074 14.7222 3.34222 14.7222 3.73333V13.6889C14.7222 14.08 14.5868 14.4148 14.316 14.6933C14.0453 14.9718 13.7197 15.1111 13.3395 15.1111H3.66049ZM3.66049 13.6889H13.3395V6.57777H3.66049V13.6889ZM3.66049 5.15555H13.3395V3.73333H3.66049V5.15555Z' fill='black'/%3E%3C/svg%3E");
}

.snapshot-icon::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM4.66667 10.6667V6.66667H1.33333V10.6667H4.66667ZM6 10.6667H10.6667V6.66667H6V10.6667ZM1.33333 5.33333H10.6667V1.33333H1.33333V5.33333Z' fill='%2339393B'/%3E%3C/svg%3E");
}

/* .snapshot-item {
    color: #0BBBEA !important;
} */
.snapshot-title {
  color: #025173;
  font-family: "Barlow";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.snapshot-bradcrumb {
  font-size: 16px;
}

.light-gauge-value {
  color: #000;
  text-align: center;
  font-family: "Barlow Condensed";
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordion h4 {
  color: #000;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 0px 8px 10px;
  border-bottom: 1px solid #9fa6ab;
  margin: 0;
}

.accordion__text {
  border-bottom: 1px solid #9fa6ab;
}

.accordion__text-header h6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  margin: 0;
}

.accordion__text-header {
  cursor: pointer;
}

.accordion__text-header h6 span {
  color: #000;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.accordion__btn {
  width: 11px;
  height: 11px;
  position: relative;
}

.accordion__btn::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.accordion__btn::after {
  content: " ";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.active .accordion__btn::after {
  display: none;
}

.accordion__text-body {
  display: none;
  padding: 0px 0px 19px;
}

.tag-list {
  padding: 0 0 0 10px;
  margin: 0;
}

.tag {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.tag:last-child {
  margin-bottom: 0;
}

.tag__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.tag__label,
.tag_recent_label,
.tag__label_snapshot {
  padding-left: 26px;
  position: relative;
  cursor: pointer;
  font-family: "Barlow";
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tag__label:before,
.tag__label_snapshot:before {
  position: absolute;
  content: " ";
  width: 14px;
  height: 14px;
  border: 2px solid #000;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tag__label:before {
  border-radius: 2px;
}
.tag__label_snapshot:before {
  border-radius: 10px;
}

.tag__input:checked + .tag__label:before,
.tag__input:checked + .tag__label_snapshot:before {
  background: #ffff;
}

.tag__input:checked + .tag__label::after,
.tag__input:checked + .tag__label_snapshot::after {
  background: #000;
}

.tag__label::after,
.tag__label_snapshot::after {
  position: absolute;
  content: " ";
  width: 6px;
  height: 6px;
  left: 3.5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.tag__label_snapshot::after {
  border-radius: 10px;
}
.menu-item.active-route {
  color: #0bbbea;
  font-weight: 500;
}

.menu-item.active-route .snapshot-icon::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM4.66667 10.6667V6.66667H1.33333V10.6667H4.66667ZM6 10.6667H10.6667V6.66667H6V10.6667ZM1.33333 5.33333H10.6667V1.33333H1.33333V5.33333Z' fill='%230BBBEA'/%3E%3C/svg%3E");
}

.pi-calendar-plus {
  line-height: 0;
}

.savings-text {
  color: #000;
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-bar {
  width: 100%;
  max-width: 382px;
  position: relative;
  margin: 0 auto;
}

.search-bar input {
  width: 100%;
  outline: none;
  padding: 10px 5px 10px 39px;
  color: #707c82;
  font-family: "Barlow";
  font-size: 11px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  border: none;
}

.layout-topbar .layout-topbar-logo {
}

.user {
  width: 300px;
  justify-content: flex-end;
  padding-right: 40px;
}

.layout-topbar {
  height: 4.6rem;
}

.account__select {
  width: 37px;
  margin-left: 14px;
  position: relative;
}

.account__select::after {
  content: " ";
  position: absolute;
  background-image: url("../images/dropdown-icon.svg");
  width: 12px;
  height: 7px;
  background-repeat: no-repeat;
  background-position: center;
  top: 64%;
  right: -6px;
  transform: translateY(-50%);
}

.account__select select {
  appearance: none;
  width: 100%;
  color: #414042;
  font-family: "Barlow";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  outline: none;
  border: none;
  cursor: pointer;
}

.card-header {
  border: none !important;
}

.card-header {
  color: #000;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-body {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.layout-sidebar-title {
  color: #025173;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-top: 1px solid #9fa6ab;
  border-bottom: 1px solid #9fa6ab;
  padding: 13px 0px;
  margin: 10px 0px;
}

.event-tracker-link {
  color: #000;
  text-align: center;
  font-family: "Barlow Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 8px;
}

.event-tracker {
  grid-template-columns: unset !important;
  width: 100%;
}

.event-tracker table {
  width: 100%;
  max-width: 284px;
  margin: 0 auto;
}

.event-tracker table tr td {
  width: 10%;
  color: #000;
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.event-tracker table tr td:nth-child(2) {
  white-space: nowrap;
}

.event-tracker table tr td:nth-child(3) {
  white-space: nowrap;
}

.event-tracker table tr td:first-child {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
}

.recent-choice-list {
  padding: 10px 0px 10px 10px;
}
.grid-product-container {
  cursor: pointer;
}

.fouling-metrics {
  border: 1px solid #000;
}

.fouling-metrics.grid3 > div {
  background-color: transparent;
  padding: 15px 10px 10px 50px;
}

@media screen and (max-width: 480px) {
  .fouling-metrics.grid3 > div {
    background-color: transparent;
    padding: 35px 20px;
  }
}

.fouling-metrics__head {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.fouling-metrics__head.no-margin {
  margin-bottom: 0;
}

.fouling-metrics__head .title,
.fouling-metrics__content .title {
  color: #025173;
  font-kerning: none;
  font-family: "Barlow";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.fouling-metrics__head span {
  color: #414042;
  font-kerning: none;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.filter-date span {
  font-size: 16px;
}

.filter-date {
  display: flex;
}

.filter-date svg {
  margin-left: 10px;
}
.fouling-metrics__content.negative-top {
  margin-top: -18px;
}

.fouling-metrics__content {
  /* padding: 0px 35px 35px 0px; */
}

.fouling-metrics__content img {
  width: 100%;
}

.chart-scale {
  color: #025173;
  text-align: right;
  font-family: "Barlow";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
}

.recent-choice-content {
  display: flex;
  align-items: center;
  margin-top: 23px;
}

.recent-choice-content__box {
  display: flex;
  padding: 5px 10px;
  margin-right: 14px;
}

.box-disable {
  background-color: #c5c5c5;
}

.close-btn {
  margin-right: 2px;
  cursor: pointer;
}

.close-btn svg {
  width: 17px;
  height: 17px;
  display: flex;
  margin-right: 4px;
}

.recent-choice-content__box span {
  color: #000;
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.fouling-metrics-title {
  border-bottom: 1px solid #9fa6ab;
}

.event-link {
  border: none;
}
.menu-item {
  cursor: pointer !important;
}
.menu-item svg {
  width: 20px;
  margin-right: 5px;
}

.menu-item.menu-item-active {
  color: #377abf;
}

.menu-item.menu-item-active svg path {
  stroke: #377abf;
  fill: #377abf;
}

.menu-item.menu-item-active .raw-data-icon svg path {
  stroke: none;
  fill: #377abf;
}

.header-title {
  color: #025173;
  font-family: "Barlow";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-wrap: wrap;
}

.box-shadow-login {
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  position: relative;
  padding: 38px 67px 50px 61px !important;
}

.box-shadow-login:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(90deg);
  border-bottom: 36px solid white;
  border-right: 36px solid #1f2d52;
}

input[type="checkbox"].checkbox-button__input {
  box-sizing: border-box;
  padding: 0;
}

input.checkbox-button__input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.checkbox-button__input {
  opacity: 1;
  /* position: absolute; */
  z-index: 99;
}

.home_grid_wrapper img {
  width: 200px !important;
}
/* //////////////////////// */
body {
  background-color: #f6f6f6;
  text-align: center;
  color: white;
  margin: 0;
}
a,
a:hover {
  text-decoration: none;
  cursor: default;
}
.carousel {
  max-width: 1002px;
  margin: auto;
}
.card-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 26px auto 0 auto;
  min-width: 230px;
  max-width: 308px;
  width: 100%;
  color: #000;
  font-size: 16px;
  text-align: left;
  box-shadow: 0px 1px 1px 0px #ccc;
}
.card-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.card-container:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  content: "";
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.card-container a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.card {
  padding: 0;
  box-shadow: 0px 1px 1px 0px #ccc;
  height: 323px;
}
.card-hero-img {
  width: 100%;
  height: 133px;
}
.card-heading-container {
  padding: 19px 19px 0;
  display: flex;
  align-items: center;
}
.card-social-icon {
  padding: 0;
  border-radius: 50px;
  max-width: 45px;
}
.card-title-container {
  padding-left: 10px;
  text-align: left;
}
h3.card-title {
  font-size: 23px;
  color: #3a3a3a;
  font-family: arial;
  font-weight: bold;
  margin: 0;
}
.card-sub-title {
  font-size: 12px;
  color: #adadad;
  font-family: arial;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}
.icon-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}
.card-text-container {
  position: relative;
}
.card-text {
  font-size: 12px;
  line-height: 1.584;
  color: #3a3a3a;
  font-family: arial;
  padding: 19px;
  margin: 0;
  text-align: left;
}
/* HEART ICON - NOT LIKED*/
.far.fa-heart,
.fas.fa-heart {
  padding: 0 0px 19px 19px;
  color: #2a8631;
  z-index: 9999999;
  display: flex;
}

.far.fa-heart,
.fas.fa-heart:hover {
  cursor: pointer;
}

.heart-liked-text {
  margin: 0;
  display: inline-block;
  padding-left: 10px;
  text-transform: uppercase;
  font-family: arial;
  font-weight: 700;
  font-size: 12px;
  margin-top: 1px;
  color: #f6f6f6;
  -webkit-transition: ease-in-out all 0.3s;
  transition: ease-in-out all 0.3s;
}
.liked {
  color: #ed1965;
}
@media (max-width: 800px) {
  .fouling-metrics__head {
    align-items: start;
    justify-content: start;
    width: 50%;
  }
  h3.card-title {
    font-size: 18px;
  }
  .card-sub-title {
    font-size: 12px;
  }
  .card-text {
    font-size: 12px;
  }
}
.layout-home-container {
  padding-top: 8rem;
}
@media (min-width: 1400px) {
  .layout-home-container {
    margin: 0 25px;
  }
}
/* -------------------------------------------------------------- */
/* SLICK REACT CAROUSEL */
/* -------------------------------------------------------------- */
.slick-next,
.slick-prev {
  top: 108.5%;
}
.slick-next {
  right: 0;
  text-align: left;
  width: 49.3%;
}
.slick-prev {
  left: 0;
  text-align: right;
  margin-right: 1.4%;
  width: 49.3%;
}
.slick-prev:before,
.slick-next:before {
  font-family: "Font Awesome\ 5 Free";
  font-size: 19px;
  color: #2a8631;
  line-height: 1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 900;
}
.slick-prev:before {
  content: "\f053";
}
[dir="rtl"] .slick-prev:before {
  content: "\f054";
}
[dir="rtl"] .slick-next {
  left: -10px;
  top: 70px;
  right: auto;
}
.slick-next:before {
  content: "\f054";
}
[dir="rtl"] .slick-next:before {
  content: "\f053";
}
.home-search-bar {
  margin-bottom: 60px;
}
.home-search-bar .form-control {
  height: 25px !important;
}
.home-search-bar .form-control:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.home-search-bar .form-control,
.home-search-bar .input-group-append,
.home-search-bar-wrapper {
  background-color: #f5f5f5;
}
.home-search-bar .form-control {
  padding-left: 5px !important;
}
.home-search-bar .btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.grid-view.active,
.list-view.active {
  background-color: #f5f5f5 !important;
}
.listing-title {
  font-family: Barlow;
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #025173;
}
.listing-sub-title {
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #bcbcbc;
  margin-bottom: 5px !important;
}
.listing-wrapper .list-view {
  background-color: transparent;
}
.load-more {
  font-family: Barlow !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
  text-decoration: underline !important;
}
.custom-container {
  max-width: 1183px;
  width: 100%;
  margin: 0 auto;
}
.home-most-recent .slick-list,
.home-most-recent .custom-row {
  margin: 0 -7px;
}
.grid-product-container {
  width: 20%;
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .grid-product-container {
    width: 25% !important;
  }
}
@media screen and (max-width: 767px) {
  .grid-product-container {
    width: 50% !important;
  }
  .box-shadow-login:before {
    border-bottom: none;
  }
}
.container-footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-bottom: 2rem;
}
